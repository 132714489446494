import { chakra, Flex } from '@chakra-ui/react';
import React from 'react';

export default function CalendarPage() {
  return (
    <Flex
      flexDir={'column'}
      w={'full'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <chakra.iframe
        src="https://parentshop.schoolzineplus.com/calendar/1?iframe=1&eventLimit=5"
        w={'full'}
        flexGrow={1}
        h={'full'}
        minH={'80vh'}
      ></chakra.iframe>
    </Flex>
  );
}
